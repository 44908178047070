import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Client from "../lib/Client"
import Dialog from "../components/Dialog";
import { Alert } from "../components/common/Alert";

import ServiceGroup, { TableHeaderService } from "../components/ServiceGroup";
import EventGroup, { TableHeaderEvent } from "../components/EventGroup";
import HostStatus from "../components/HostStatus";
import { IEventGroup } from '../../../server/src/models/EventGroup'
import { IHost } from '../../../server/src/models/Host'

import { WithId } from 'mongodb'

import { Box, CircularProgress, Paper, Typography, Grid, FormControlLabel, Switch, Breadcrumbs, Link } from '@mui/material/'
import { Table, TableBody, TableContainer } from '@mui/material'

import Explain from '../components/Explain';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import { ObjectId } from "mongodb";
import OS from "../components/common/OS";


const group = (groupedEvents: WithId<IEventGroup>[]): {[key: string]: WithId<IEventGroup>[]} => {
    const result: {[key: string]: WithId<IEventGroup>[]} = {}
    for(const element of groupedEvents) {
        const key = element.service.name
        if(result[key] === undefined)  result[key] = [element]
        else result[key].push(element)
    }
    return result
}

const Host = () => {
    const routeParams = useParams();
    const id = routeParams.id!

    const [hostInfo, setHostInfo] = React.useState<WithId<IHost>>()
    const [groupedEvents, setGroupedEvents] = React.useState<{[key: string]: WithId<IEventGroup>[]}>({})
    const [events, setEvents] = React.useState<WithId<IEventGroup>[]>([])
    const [loaded, setLoaded] = React.useState(false);
    const [eventsLoaded, setEventsLoaded] = React.useState(false);
    const [groupByService, setGroupByService] = React.useState(true)

    // explain
    const [explainOpen, setExplainOpen] = useState(false)
    const [explainGroup, setExplainGroup] = useState<WithId<IEventGroup>>()
    const handleExplain = (group: WithId<IEventGroup>) => {
        setExplainGroup(group)
        setExplainOpen(true)
    }

    // select
    const [selectedIds, setSelectedIds] = useState<ObjectId[]>([])
    const [indeterminate, setIndeterminate] = React.useState(false);

    const handleSelect = (selected: boolean, eventGroupId: ObjectId|null) => {
        //console.log('root select:', selected, eventGroupId)
        let updatedSelection: ObjectId[] = []

        if(eventGroupId) {
            if(selected) updatedSelection = [...selectedIds, eventGroupId]
            else updatedSelection = selectedIds.filter(e => e !== eventGroupId)
        }
        else { // all
            if(selected) updatedSelection = events.map((e) => e._id)
        }
        setSelectedIds(updatedSelection)
    }

    // effect: bring checkbox into correct state whenever selection changes
    React.useEffect(() => {
        if(selectedIds.length === 0 || selectedIds.length === events.length ) setIndeterminate(false)
        else setIndeterminate(true)

    }, [selectedIds, events.length])



    // reload
    const reload = () => {
        setSelectedIds([])
        setIndeterminate(false)
        setLoaded(false)
        setEventsLoaded(false)
    }

    // load data
    React.useEffect(() => {
        if(!loaded) {
            Client.instance.host(id).then((data) => {
                setHostInfo(data)
                Client.instance.hostEvents(id).then((data) => {
                    setGroupedEvents(group(data))
                    setEvents(data)
                    setEventsLoaded(true)
                    setLoaded(true)
                })
            })
        }
    }, [loaded, id])

    return(
        <React.Fragment>
            <Explain open={explainOpen} setOpen={setExplainOpen} group={explainGroup}/>

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/hosts">Hosts</Link>
                <Typography color="text.primary">Anomalies</Typography>
            </Breadcrumbs>

            <Typography variant="h3" gutterBottom >
                <OS name={hostInfo?.family} />
                {hostInfo?.name}
            </Typography>

            <Typography variant="caption">
                {hostInfo?.osDetails}
            </Typography>

            <div style={{height: 30}}></div>

            { hostInfo && <HostStatus details={hostInfo} /> }

            <div style={{height: 50}}></div>

            <Typography variant="h5" gutterBottom>Anomalies</Typography>

            { !eventsLoaded &&
            <Box textAlign='center'>
               <CircularProgress />
               <br/>
               <Typography variant="caption">processing...</Typography>
            </Box>
            }
            { eventsLoaded &&

            <>
            { Object.keys(events).length > 0 &&
                <Grid container justifyContent="flex-start">
                    <FormControlLabel
                        sx={{mr:10}}
                        label="Group by Service"
                        control={
                            <Switch checked={groupByService} onChange={() => { setGroupByService(!groupByService)} } />
                        }
                    />
                    { selectedIds.length > 0 &&
                        <>
                        <Typography variant="caption" style={{paddingTop: 10, marginRight: 10}}>{`${selectedIds.length} selected:`}</Typography>
                    <Dialog
                        buttonLabel="Discard"
                        disabled={selectedIds.length === 0}
                        startIcon={<DeleteIcon/>}
                        title={`Discard ${selectedIds.length} event groups?`}
                        description="Are you sure you want to delete all selected events?"
                        proceedLabel="Delete"
                    onConfirm={() => {
                        Client.instance.discardSelected(hostInfo!._id, selectedIds).then(() => { reload() })
                    }}/>
                    <Dialog
                        buttonLabel="Learn"
                        disabled={selectedIds.length === 0}
                        startIcon={<AddToQueueIcon/>}
                        title={`Learn ${selectedIds.length} event groups?`}
                        description="Are you sure you want to learn all selected events for this host?"
                        proceedLabel="Learn"
                    onConfirm={() => {
                        Client.instance.learnSelected(hostInfo!._id, selectedIds).then(() => { reload() })
                    }}/>
                    </>
                }
                </Grid>
            }

            { Object.keys(events).length === 0 &&
                <Alert severity={ hostInfo?.mode === 'init' ? 'warning' : 'success' }>
                    { hostInfo?.mode === 'init' ? 'Initial training in progress. This might take a few minutes.' : 'Waiting for events' }
                </Alert>
            }

            { Object.keys(events).length > 0 &&
                <TableContainer component={Paper}>
                    <Table>
                        { groupByService &&
                        <TableHeaderService selectCallback={handleSelect} indeterminate={indeterminate} checked={selectedIds.length > 0}/>
                        }
                        { !groupByService &&
                        <TableHeaderEvent selectCallback={handleSelect} indeterminate={indeterminate} checked={selectedIds.length > 0}/>
                        }

                        <TableBody>
                            { groupByService &&
                                Object.keys(groupedEvents).map((service: string) => {
                                    return <ServiceGroup
                                        service={groupedEvents[service][0].service}
                                        groups={groupedEvents[service]}
                                        hostId={hostInfo?._id}
                                        key={`${service}-${hostInfo?._id}`}
                                        reloadFunction={reload}
                                        handleExplain={handleExplain}
                                        rootSelection={selectedIds}
                                        setRootSelection={setSelectedIds}
                                    />
                                })
                            }
                            {
                                !groupByService &&
                                events.map((group: WithId<IEventGroup>) => {
                                    return <EventGroup
                                        group={group}
                                        key={group._id.toString()}
                                        reloadFunction={reload}
                                        showServiceName
                                        handleExplain={handleExplain}
                                        checked={selectedIds.indexOf(group._id) > -1}
                                        selectCallback={handleSelect}
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </>
            }

        </React.Fragment>
    )
}

export default Host
