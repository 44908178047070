import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { routes } from "./routes";

import Client from './lib/Client';

import MainLayout from "./components/layout/MainLayout";
import Setup from './pages/Setup';
import Signup from './pages/Signup';
import Login from './pages/Login';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

//import Login from './pages/Login';

export default function App() {
    const [authenticated, setAuthenticated] = useState(Client.instance.authToken !== undefined)

    const loginCallback = () => {
        // replace /login with / in browser
        setAuthenticated(true)
        if(window.location.pathname === '/login') //window.history.pushState("", "", "/")
            window.location.pathname = "/"
    }

    if(!authenticated && !['/setup', '/login'].includes(window.location.pathname))
        return <Login callback={loginCallback}/>

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {routes}
                </Route>
                <Route path="/setup" element={<Setup />}></Route>
                <Route path="/login" element={<Login callback={loginCallback} />}></Route>
                <Route path="/signup" element={<Signup />}></Route>
            </Routes>
        </BrowserRouter>
    )
}