import { Tooltip } from '@mui/material/'
import CircleIcon from '@mui/icons-material/Circle';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

type Props = {
    value: number
    isAverage?: boolean
}
export default (props: Props) => {
    let tooltip = `${props.value}% recognized`
    if(props.isAverage) tooltip += ' on average'

    let color = 'red'
    let items = 3

    if(props.value >= 20) {
        items = 2
        color = "orange"
    }
    if(props.value >= 40) {
        items = 1
    }

    let itemsArray = []
    for(let i = 0; i < items; i++) {
        itemsArray.push(
            <CircleIcon sx={{fontSize:8, fill:color}} key={`cf${i}`} />
        )
    }
    for(let i=itemsArray.length; i<3; i++ ) {
        itemsArray.push(
            <TripOriginIcon sx={{fontSize:8, fill:color}} key={`ce-${i}`} />
        )
    }

    return(
        <Tooltip title={tooltip}>
            <div style={{flex: 1, flexDirection: 'row'}}>
                {itemsArray}
            </div>
        </Tooltip>
    )
}