import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

type Props = {
    buttonLabel: string
    onConfirm: Function
    title: string
    description: string
    proceedLabel?: string
    startIcon?: React.ReactNode
    disabled?: boolean
}

export default (props: Props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Button
            variant="outlined"
            onClick={handleClickOpen}
            sx={{mb: 2, mr: 2}}
            startIcon={props.startIcon}
            disabled={props.disabled}
        >
          {props.buttonLabel}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { props.description }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>Cancel</Button>
            <Button color="error" onClick={ () => {
                handleClose()
                props.onConfirm()
                }}>{ props.proceedLabel || 'Proceed'}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }