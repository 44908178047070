import React from "react";
import { Drawer, List, Stack, Toolbar, IconButton, AppBar, Typography} from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";


import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountMenu from "./AccountMenu";

const Logo = () => {
  return (
    <a href="/"><img src={assets.images.logo} width={200} style={{marginTop: 20}}/></a>
  )
}



/*const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));*/

type Props = {
    mobile: boolean
}
const Sidebar = (props: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerClose = () => {
    setOpen(false)
  };

  const clickCallbackMobile = () => {
    if(props.mobile) setOpen(false)
  }

  return (
    <>
    { props.mobile &&
        <AppBar style={{background: colorConfigs.sidebar.bg}}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    style={{marginLeft:10}}
                    //sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                </Typography>
                <AccountMenu mobile={props.mobile}/>

            </Toolbar>
        </AppBar>
    }
    {!props.mobile &&
    <div style={{width:props.mobile ? 0 : sizeConfigs.sidebar.width  }}>
    <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        style={{marginLeft:10}}
        //sx={{ mr: 2, ...(open && { display: 'none' }) }}
    >
        <MenuIcon />
    </IconButton>
</div>
    }


    <Drawer
    onClick={clickCallbackMobile}
      variant={props.mobile ? 'temporary' : 'permanent'}
      open={open}
      sx={{
        //width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color
        }
      }}
    >
        <IconButton onClick={handleDrawerClose} style={{display: props.mobile ? 'block' : 'none', width:32, alignSelf:'flex-end', marginRight: 15}}>
        <ChevronLeftIcon style={{fill: 'white'}} />
        </IconButton>

      <List disablePadding>
        <Toolbar sx={{ marginBottom: "20px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <Logo />
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) => (
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        ))}
      </List>
    </Drawer>
    </>
  );
};

export default Sidebar;