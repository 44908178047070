import React, { useState } from "react";
import { ObjectId, WithId } from "mongodb";

import { IEventGroup } from '../../../server/src/models/EventGroup'
import Event from "./Event";
import Score from "./Score";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Collapse, IconButton, Link, Menu, MenuItem, Badge } from '@mui/material/'
import Client from "../lib/Client";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


type TableHeaderProps = {
    showHost?: boolean
    selectCallback?: Function
    indeterminate?: boolean
    checked?: boolean
}

export const TableHeaderEvent = (props: TableHeaderProps) =>
        <TableHead>
            <TableRow>
                <TableCell style={{width:160}}>
                    { props.selectCallback &&
                        <Checkbox
                            sx={{ml: 4}}
                            indeterminate={props.indeterminate}
                            checked={props.checked}
                            onClick={(e:any) => { props.selectCallback!(e.target.checked) }}/>
                    }
                </TableCell>
                { props.showHost && <TableCell style={{width:20}}>Host</TableCell>}
                <TableCell style={{width:20}}>Count</TableCell>
                <TableCell style={{width:20}}>Score</TableCell>
                <TableCell style={{minWidth: 600}}>Event</TableCell>
            </TableRow>
        </TableHead>


const Counter = (props: {count: number}) =>
    <Badge>

    </Badge>

type Props = {
    group: WithId<IEventGroup>
    showHost?: boolean
    reloadFunction: Function
    showServiceName?: boolean
    handleExplain?: Function
    checked?: boolean
    selectCallback?: Function

}

export default (props: Props) => {
    const [showDetails, setShowDetails] = useState(false)
    const group = props.group
    let cleanTitle = ''
    if(group.events && group.events.length > 0) {
        cleanTitle = group.events![0].entry.replace(/^\S* \S*/, '')
    }
    else {
        cleanTitle = group.mask.replace(/^\S* \S* \S* \S*:/, '')
    }

    cleanTitle = cleanTitle.trim()
    if(!props.showServiceName)
        cleanTitle = cleanTitle.replace(/^\S* /, '')

    let score: number = 0
    if(group.score) score = Math.floor(group.score * 100)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <React.Fragment key={group._id.toString()}>
            <TableRow>
                <TableCell style={{width:160}}>
                    <IconButton aria-label="expand row" size="small"
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    { props.selectCallback &&
                    <Checkbox checked={props.checked} onClick={(e:any) => {
                        props.selectCallback!(e.target.checked, props.group._id)
                    }}/>
                    }

                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        { props.handleExplain &&

                        <MenuItem onClick={() => {
                            handleClose()
                            if(props.handleExplain)
                                props.handleExplain(group)
                             }}>
                                <HelpOutlineIcon />
                                &nbsp; Explain
                        </MenuItem>
                        }

                        <MenuItem onClick={() => {
                            Client.instance.learnEventGroup(group).then(() => { props.reloadFunction() })
                            handleClose()
                             }}>
                                <AddToQueueIcon />
                                &nbsp; Learn
                        </MenuItem>

                        <MenuItem onClick={() => {
                            Client.instance.discardEventGroup(group).then(() => { props.reloadFunction() })
                            handleClose()
                             }}>
                                <DeleteIcon />
                                &nbsp; Discard
                        </MenuItem>
                    </Menu>
                </TableCell>
                {props.showHost &&
                <TableCell>{ props.showHost && <Link style={{marginRight: 4}} href={`/hosts/${props.group.host?._id.toString()}`}>{props.group.host?.name}</Link>}</TableCell>
                }
                <TableCell>{group.events?.length}</TableCell>
                <TableCell style={{width:80}}><Score value={score} /></TableCell>
                <TableCell>{cleanTitle}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={showDetails} timeout="auto" unmountOnExit>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell rowSpan={4}>
                                        {
                                        props.group.events?.map((e) => { return <Event event={e} key={e._id.toString()}/> })
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


