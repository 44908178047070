import React, { useState } from "react";
import { IEventGroup } from '../../../server/src/models/EventGroup'
import Score from "./Score";
import EventGroup from "./EventGroup";
import Client from "../lib/Client";

import { Collapse, IconButton, Menu, MenuItem, Badge} from '@mui/material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { ObjectId, WithId } from "mongodb";
import DeleteIcon from '@mui/icons-material/Delete';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

import { IService } from '../../../server/src/models/Service'
import Checkbox from '@mui/material/Checkbox';

type TableHeaderProps = {
    selectCallback?: Function
    checked?: boolean
    indeterminate?: boolean
}

export const TableHeaderService = (props: TableHeaderProps) =>
        <TableHead>
            <TableRow>
                <TableCell style={{width:160}}>
                    {   props.selectCallback &&
                        <Checkbox
                            sx={{ml: 4}}
                            indeterminate={props.indeterminate}
                            checked={props.checked}
                            onClick={(e:any) => { props.selectCallback!(e.target.checked) }}
                    />
                    }
                </TableCell>
                <TableCell style={{width:20}}>Count</TableCell>
                <TableCell style={{width:20}}>Score</TableCell>
                <TableCell style={{width:20}}>Service</TableCell>
                <TableCell>Description</TableCell>
            </TableRow>
        </TableHead>

type Props = {
    hostId: ObjectId | undefined
    service: WithId<IService>
    groups: WithId<IEventGroup>[]
    reloadFunction: Function
    showHost?: boolean
    handleExplain?: Function
    rootSelection: ObjectId[]
    setRootSelection: Function
}

export default (props: Props) => {
    const [showDetails, setShowDetails] = useState(false)
    let score = 0
    for (const group of props.groups) {
        score += group.events![0].score
    }
    score /= props.groups.length
    score = Math.floor(score * 100)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [parentChecked, setParentChecked] = useState(false)
    const [selectedIds, setSelectedIds] = useState<ObjectId[]>([])
    const [indeterminate, setIndeterminate] = React.useState(false);

    React.useEffect(() => {
        let initialSelection: ObjectId[] = []
        for(const group of props.groups) {
            if(props.rootSelection.indexOf(group._id) > -1)
                initialSelection.push(group._id)
        }
        if(initialSelection.length == 0 || initialSelection.length == props.groups.length ) setIndeterminate(false)
        else setIndeterminate(true)

        setParentChecked(initialSelection.length > 0)
        setSelectedIds(initialSelection)

    }, [props.rootSelection])

    const handleSelect = (checked: boolean, eventGroupId: ObjectId|null) => {
        let idsToChange: ObjectId[]
        if(eventGroupId) {
            idsToChange = [eventGroupId]
        }
        else { // all children
            setIndeterminate(false)
            idsToChange = props.groups.map((e) => e._id) // all events in this service
        }

        let newLocalSelection: ObjectId[] = []
        if(checked) { // add
            newLocalSelection = [...selectedIds, ...idsToChange]
            setSelectedIds(newLocalSelection)
            // edit parent
            props.setRootSelection((ids: ObjectId[]) => [...ids, ...idsToChange])
        }
        else { // remove
            newLocalSelection = selectedIds.filter(id => idsToChange.indexOf(id) == -1)
            setSelectedIds(newLocalSelection)
            // edit parent
            props.setRootSelection(props.rootSelection.filter(id => idsToChange.indexOf(id) == -1))
        }

        console.log(selectedIds)
        if(newLocalSelection.length == 0 || newLocalSelection.length == props.groups.length ) setIndeterminate(false)
        else setIndeterminate(true)
        setParentChecked(checked)

    }


    return(
        <React.Fragment key={`${props.service}-${props.hostId}-root`}>
        <TableRow key={`${props.service}-${props.hostId}`}>
            <TableCell>
                <IconButton aria-label="expand row" size="small"
                    onClick={() => setShowDetails(!showDetails)}
                >
                    {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                { props.rootSelection &&
                     <Checkbox
                        indeterminate={indeterminate}
                        checked={parentChecked}
                        onClick={(e:any) => { handleSelect(e.target.checked, null) }}/>
                    }
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            Client.instance.learnServiceGroup(props.groups)
                                .then(() => { props.reloadFunction() });

                                handleClose()
                            }
                    }>
                        <AddToQueueIcon/>&nbsp; Learn
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            Client.instance.discardServiceGroup(props.groups)
                                .then(() => { props.reloadFunction() });

                            handleClose()
                        }
                    }>
                        <DeleteIcon />&nbsp; Discard
                    </MenuItem>
                </Menu>
            </TableCell>
            <TableCell>{props.groups.length}</TableCell>
            <TableCell><Score value={score} isAverage/></TableCell>

            { props.showHost &&
            <TableCell></TableCell>
            }
            <TableCell>
                {props.service.name}
            </TableCell>
            <TableCell>{props.service.description}</TableCell>
        </TableRow>
        <TableRow key={`${props.service}-${props.hostId}-sub`}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={showDetails} timeout="auto" unmountOnExit>
                    <Table>
                        <TableBody>
                            {
                            props.groups.map((e) => <EventGroup
                                                        group={e}
                                                        key={`eventGroup-${e._id}`}
                                                        reloadFunction={props.reloadFunction}
                                                        handleExplain={props.handleExplain}
                                                        selectCallback={handleSelect}
                                                        checked={selectedIds.indexOf(e._id) > -1}
                                                        />)
                            }
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    )
}
