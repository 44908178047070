import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Toolbar, Button } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import AccountMenu from "../common/AccountMenu";

const breakPoint = 768

const MainLayout = () => {
    const navigate = useNavigate()

    const [mobile, setMobile] = React.useState(window.innerWidth < breakPoint)

    const callback = (e:any) => {
        const newValue = e.currentTarget.innerWidth < breakPoint
        if(mobile != newValue) setMobile(newValue)
    }

  React.useEffect(() => {
    window.addEventListener('resize', (callback));
    return () => window.removeEventListener('resize', callback);
  })



    /*
    <Box
            component="nav"
            sx={{
            width: sizeConfigs.sidebar.width,
            flexShrink: 0
            }}
        >
            <Sidebar />
        </Box>
    */

    return (
        <Box sx={{ display: "flex" }}>
        <Sidebar mobile={mobile}/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                minHeight: "100vh",
                backgroundColor: colorConfigs.mainBg
            }}
        >
            {!mobile &&
                <Box style={{position: 'absolute', right: 20, top: 20}}>
                <Box sx={{ display: 'flex', alignContent:'flex-end', alignItems: 'right'}}>
                <AccountMenu mobile={false} />
                </Box>
                </Box>
            }
            <div style={{marginTop: mobile ? 80 : 80}}>
                <Outlet/>
            </div>
        </Box>
        </Box>
    );
};

export default MainLayout;