import { Chip } from "@mui/material"

type Props = {
    password: string
}

const minPasswordLength = 8

const PasswordStrength = ({password}: Props) => {
    password.trim()

    const minLength = password.length >= minPasswordLength ? 'success': 'warning'
    const upperLowerCase = (password.match(/[a-z]/) && password.match(/[A-Z]/)) ? 'success': 'warning'
    const number = password.match(/[0-9]/) ? 'success': 'warning'
    const specialChar = password.match(/[^A-Za-z0-9 ]/) ? 'success': 'warning'

    return(
        <div style={{flex:1}}>
        <Chip size="small" label="Length" color={minLength} sx={{m:1}}/>
        <Chip size="small" label="Upper- and lower case" color={upperLowerCase} sx={{m:1}}/>
        <Chip size="small" label="Number" color={number} sx={{m:1}}/>
        <Chip size="small" label="Special Char" color={specialChar} />
        </div>
    )

}

export default PasswordStrength