import React from 'react';
import Client from '../lib/Client';
import { ITenant } from '../../../server/src/models/Tenant'

import { TextField, Box, CircularProgress, Divider, Paper, Typography, Button, Snackbar, ButtonGroup, MenuItem, FormControl, Select, SelectChangeEvent, Breadcrumbs, Link } from '@mui/material/'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Alert } from "../components/common/Alert";
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/joy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import axios from 'axios'


export default () => {
    const [tenant, setTenant] = React.useState<ITenant>()
    const [loaded, setLoaded] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [reveal, setReveal] = React.useState(false);

    const fetchData = React.useCallback(() => {
        Client.instance.tenant().then((data) => {
            setTenant(data)
            setLoaded(true)
            })
        }, [tenant, loaded])

    if(!loaded) fetchData()

    /* <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">{Client.instance.currentTenant?.name}</Typography>
            </Breadcrumbs>
*/
    // <Typography variant="h3" gutterBottom><SettingsIcon fontSize='large' /> Settings</Typography>
    return(
        <>



            { tenant &&
            <Paper style={{padding: 30}}>
                <Typography variant='caption'>API</Typography>
                <Typography variant='h5' gutterBottom>Access Key</Typography>
                <Typography gutterBottom>This API key is required by the agent to communicate with the server.</Typography>
                { reveal &&
                <Typography variant='overline'>{tenant?.apiKey}</Typography>
                }
                { !reveal &&
                    <Button startIcon={<VisibilityIcon/>} variant="outlined" sx={{m:2}} onClick={() => setReveal(true) }>
                        Reveal
                    </Button>
                }

                <CopyToClipboard text={tenant?.apiKey} onCopy={() => setCopied(true)}>
                    <Button startIcon={<ContentPasteIcon/>} variant="outlined" sx={{m:2}}>Copy to Clipboard</Button>
                </CopyToClipboard>

                <Snackbar open={copied} autoHideDuration={6000} onClose={() => { setCopied(false) }}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        API key copied to clipboard.
                    </Alert>
                </Snackbar>
            </Paper>
            }

            <Paper style={{padding: 30, marginTop:20}}>
                <Typography variant='caption'>Quickstart</Typography>
                <Typography variant='h5' gutterBottom>Linux Client Installation</Typography>
                <p>1. Make sure latest node engine is installed, for instance:</p>
                <code>apt install node</code>

                <p>2. Create a directory, download and extract client:</p>
                <code>mkdir /opt/depact-agent && cd $_</code>
                <br/>
                <code>wget -O - {axios.defaults.baseURL}/download | tar zx</code>

                <p>3. Run setup script to complete the installation:</p>
                <code>./setup.sh</code>

            </Paper>
        </>
    )
};
