const assets = {
    images: {
      logo: require("./images/logo.png"),
      netsense: require("./images/netsense.png"),
      linux: require('./images/linux.png'),
      apple: require('./images/apple.png'),
      windows: require('./images/windows.png'),
    }
  };

  export default assets;