import assets from "../../assets"
import { Tooltip, Avatar } from '@mui/material/'


type Props = {
    name: string|undefined
}

const minPasswordLength = 8

export default ({name}: Props) => {
    if(!name) return null

    let image = assets.images.windows
    if(name == 'linux') image = assets.images.linux
    if(name == 'darwin') image = assets.images.apple

    const tooltip = name[0].toUpperCase() + name.slice(1)
    const size = 48

    return <Tooltip title={tooltip} style={{float:'left', marginRight: 15, marginTop:2}}>
                <Avatar src={image} sx={{ width: size, height: size, opacity: 0.8 }}/>
            </Tooltip>


}