import assets from '../../assets';

const Logo = () => {
    return (
      <div>
        <img src={assets.images.logo} width={250} alt="depact" />
      </div>
    )
}

export default Logo;
