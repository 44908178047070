/* kept this file as a template for later use */
import { useState } from 'react';

import Button from '@mui/material/Button';
import { Box, Typography, Chip, Grid, Divider, FormControl, FormLabel, Stack, Snackbar, InputLabel, Input, Card, CardContent, CardHeader, CardActions } from '@mui/material';
import { Alert } from "../components/common/Alert";
import Client from '../lib/Client';
import assets from '../assets';
import colorConfigs from "../configs/colorConfigs";
import Logo from '../components/common/Logo';


type Props = {
    callback: Function
}

const Login = (props: Props) => {
    //const navigate = useNavigate()

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const enableLogin = user.length > 0 && password.length > 0
    const [hasLoggedIn, setHasLoggedIn] = useState(false)

    // terminate existing sessions
    if(!hasLoggedIn && Client.instance.authToken) {
        console.log("do logout")
        Client.instance.logout()
    }

    const handleLogin = async (e: any) => {
        if(!enableLogin) return

        Client.instance.authenticate(user, password).then((result) => {
            console.log(result)
            setShowError(!result.success)
            setErrorMessage(result.message || '')


            if(result.success) {
                setHasLoggedIn(true)
                props.callback()
            }
        })
    }

    return(
        <Box sx={{ height: '100vh', flexDirection: 'row', background: colorConfigs.sidebar.bg, textAlign: 'center', paddingTop: 10 }}>
            <Logo />
            <Card style={{marginTop: 30, paddingBottom: 50}}>
        <Grid alignItems="center" justifySelf="center" flex={1}
        display="flex" flexDirection="column" gap={3} marginTop={10}>
                <Typography component="h5" variant="h5">
                    Please sign in
                </Typography>

                <InputLabel htmlFor="input-user">Username</InputLabel>
                <Input id="input-user" required autoFocus
                onChange={e => setUser(e.currentTarget.value)}
                />

                <InputLabel htmlFor="input-password">Password</InputLabel>
                <Input id="input-password" type='password' required
                onChange={e => setPassword(e.currentTarget.value)}
                onKeyDown={(e) => { if(e.key == "Enter") handleLogin(e) } }
                onSubmit={handleLogin}
                />


            <Snackbar open={showError} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => { setShowError(false) }}>
            <Alert severity="error" sx={{ width: '100%' }}>
                { errorMessage }
            </Alert>
            </Snackbar>
            <Button disabled={!enableLogin} onClick={handleLogin} variant="contained">Sign In</Button>

            {false && <img style={{position:'absolute', bottom: 20}} src={assets.images.netsense} width={120} /> }

        </Grid>
        </Card>
        <Typography variant='caption' style={{color: '#ccc'}}>&copy; {new Date().getFullYear() } netsense GmbH</Typography>

        </Box>
    )
}

export default Login;
