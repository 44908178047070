import { IHost } from '../../../server/src/models/Host'
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';

type StatusProps = {
    host: IHost
}

const Status = (props: StatusProps) => {
    let online = false
    try {
        const now = new Date().getTime()
        const updated = Date.parse(props.host.updated.toString()) // Date.parse returns ms
        const diff = now - updated
        online = diff < 60000 // ms
    } catch {}

    let color = "grey"
    let tooltip = "Disconnected"

    if(online)
        if(props.host.mode === 'monitor') {
            color = "green"
            tooltip = "Connected"

        }
        else if (props.host.mode === 'train') {
            color = "purple"
            tooltip = "Training"
        }
        else if (props.host.mode === 'init') {
            color = "purple"
            tooltip = "Initializing"
        }
        else {
            color = "orange"
            tooltip = "Suspended"
        }

    return <Tooltip title={tooltip}>
                <CircleIcon sx={{fontSize: 12, color: color, marginRight: 1}}/>
            </Tooltip>
}

export default Status