import React from 'react';
import Client from '../lib/Client';
import { InputLabel, Input, Grid, Typography, Button, Snackbar, FormControl } from '@mui/material/'
import { Alert } from "../components/common/Alert";
import { useNavigate } from "react-router-dom";
import PasswordStrength from '../components/common/PasswordStrength';
import Logo from '../components/common/Logo';

const Signup = () => {

    const [tenant, setTenant] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordConfirmation, setPasswordConfirmation] = React.useState("")

    const [showError, setShowError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const navigate = useNavigate()

    const handleSubmit = () => {
        const data = {
            tenant: tenant,
            username: username,
            email: email,
            password: password,
            passwordConfirmation: passwordConfirmation
        }
        Client.instance.signup(data).then((result) => {
            console.log(result)
            setShowError(!result.success)
            setErrorMessage(result.message)

            if(result.success) {
                navigate("/")
            }
        })

    }

    return(
            <Grid
                alignItems="center" justifyContent="center" justifySelf="center"
                display="flex" flexDirection="column" gap={3} marginTop={20}
            >
                <Logo />
                <Typography variant="h5" gutterBottom>Create Preview Account</Typography>

                <FormControl>
                    <InputLabel htmlFor="my-input-tenant">Tenant Name</InputLabel>
                    <Input id="my-input-tenant" aria-describedby="my-helper-text-tenant" value={tenant} required autoFocus onChange={(text) => setTenant(text.currentTarget.value)}/>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="my-input-user">Username</InputLabel>
                    <Input id="my-input-user" aria-describedby="my-helper-text-user" value={username} required onChange={(text) => setUsername(text.currentTarget.value)}/>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="my-input-email">Email</InputLabel>
                    <Input id="my-input-email" aria-describedby="my-helper-text-email" value={email} required onChange={(text) => setEmail(text.currentTarget.value)}/>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="my-input-password">Password</InputLabel>
                    <Input id="my-input-password" aria-describedby="my-helper-text-password" type='password' required value={password} onChange={(text) => setPassword(text.currentTarget.value)}/>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="my-input-password-confirm">Confirm</InputLabel>
                    <Input id="my-input-password-confirm" aria-describedby="my-helper-text-password-confirm" type='password' required value={passwordConfirmation} onChange={(text) => setPasswordConfirmation(text.currentTarget.value)} />
                </FormControl>

                <PasswordStrength password={password} />

                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Create Account
                </Button>

                <Snackbar open={showError} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => { setShowError(false) }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        { errorMessage }
                    </Alert>
                </Snackbar>
            </Grid>
    )
};

export default Signup