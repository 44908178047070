import React from "react";
import { Box, CircularProgress, Typography } from '@mui/material/'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { IEventGroup } from "../../../server/src/models/EventGroup";
import Client from "../lib/Client";
import { WithId } from "mongodb";
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';

const PaperComponent = (props: PaperProps) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    )
  }

type Props = {
    open: boolean
    setOpen: Function
    group?: WithId<IEventGroup>
}

const Explain = (props: Props) => {
    const [explanation, setExplanation] = React.useState<string>()

    const handleClose = () => { props.setOpen(false) }

    if(props.group && !explanation) {
        //console.log("explain", props.group)
        if(props.group.explanation) {
            setExplanation(props.group.explanation)
        }
        else {
            Client.instance.explain(props.group).then((data) => {
                setExplanation(data)
            })
        }
    }

    return (
        <Dialog
          open={props.open}
          onClose={handleClose}
          fullWidth
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="draggable-dialog-description"
        >

            <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
                Explain Events
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h5" gutterBottom>Event Type</Typography>

                <code>{props.group?.mask}</code>

                <Typography variant="h5" sx={{mt: 5}} gutterBottom >Explanation</Typography>
                { explanation &&
                    <Typography variant="body1">{explanation}</Typography>
                }
                { !explanation &&
                    <Box textAlign='center'>
                        <CircularProgress />
                        <br/>
                        <Typography variant="caption">processing...</Typography>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                    {/*
                    <Button onClick={handleClose} color="warning" startIcon={<AddToQueueIcon />}>Learn</Button>
                    <Button onClick={handleClose} color="warning" startIcon={<DeleteIcon />}>Discard</Button>
                    */}
                    <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
  }

  export default Explain