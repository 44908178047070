import React from 'react';
import Client from '../lib/Client';

import { Grid, InputLabel, Input, TextField, Box, CircularProgress, Divider, Paper, Typography, Button, Snackbar, ButtonGroup, MenuItem, FormControl, Select, SelectChangeEvent, Breadcrumbs, Link } from '@mui/material/'
import { Alert } from "../components/common/Alert";

import PersonIcon from '@mui/icons-material/Person';
import PasswordStrength from '../components/common/PasswordStrength';

export default () => {
    const [currentPassword, setCurrentPassword] = React.useState("")
    const [newPassword, setNewPassword] = React.useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState("")

    const [message, setMessage] = React.useState<string>()
    const [success, setSuccess] = React.useState(false)

    const handlePasswordChange = () => {
        Client.instance.changePassword(currentPassword, newPassword, newPasswordConfirmation)
            .then((result) => {
                setSuccess(result.success)
                setMessage(result.message)
                if(result.success) {
                    setCurrentPassword("")
                    setNewPassword("")
                    setNewPasswordConfirmation("")
                }
            })

    }
    return (
        <>
            <Typography variant="h3" gutterBottom><PersonIcon fontSize='large' /> Profile</Typography>

            <Paper sx={{padding:3}}>
                <Typography variant='h5' gutterBottom>Change Password</Typography>
                <Grid
                    maxWidth={300}
                    justifySelf="center"
                    display="flex"
                    flexDirection="column"
                    gap={3}
                >
                <FormControl>
                    <InputLabel htmlFor="my-input-current-password">Current Password</InputLabel>
                    <Input id="my-input-current-password" aria-describedby="my-helper-text-password" type='password' required value={currentPassword} onChange={(text) => setCurrentPassword(text.currentTarget.value)}/>
                </FormControl>

                <FormControl>
                    <InputLabel htmlFor="my-input-new-password">New Password</InputLabel>
                    <Input id="my-input-new-password" aria-describedby="my-helper-text-password" type='password' required value={newPassword} onChange={(text) => setNewPassword(text.currentTarget.value)}/>
                </FormControl>
                <PasswordStrength password={newPassword} />




                <FormControl>
                    <InputLabel htmlFor="my-input-password-confirm">Confirm</InputLabel>
                    <Input id="my-input-password-confirm" aria-describedby="my-helper-text-password-confirm" type='password' required value={newPasswordConfirmation} onChange={(text) => setNewPasswordConfirmation(text.currentTarget.value)} />
                </FormControl>

                <Button variant='outlined' onClick={handlePasswordChange} >Change Password</Button>

                </Grid>


            </Paper>


            <Snackbar open={(message != undefined)} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => { setMessage(undefined) }}>
                <Alert severity={success ? 'success' : 'error'} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

        </>
    )
}