import { WithId, ObjectId } from "mongodb";
import axios, { AxiosError, AxiosResponse } from 'axios'
import  secureLocalStorage  from  "react-secure-storage";

import { TIntent, IAuthenticationResponse } from '../../../server/src/sharedInterfaces'
import { IHost } from '../../../server/src/models/Host'
import { IEventGroup } from '../../../server/src/models/EventGroup'
import { ITenant } from '../../../server/src/models/Tenant'

let uri = `${window.location.protocol}//${window.location.hostname}`
if(window.location.port !== '') uri = `${uri}:${window.location.port}`
if(process.env.NODE_ENV !== 'production') uri = "http://localhost:3000"

axios.defaults.baseURL = uri
axios.defaults.headers.common['Content-Type'] = 'application/json'
//console.log('using api', axios.defaults.baseURL)

enum Method {
    get,
    post,
    patch,
    delete
}

export default class Client {

    authToken: string|undefined
    currentTenant: ITenant | undefined
    availableTenants: ITenant[] = []

    private static _instance: Client

    // use this to access datamanager from anywhere
    // e.g. DataManager.instance.client
    static get instance(): Client {
        if (Client._instance == null)
            Client._instance = new Client()

        return this._instance
    }

    constructor() {
        // load auth token from storage
        this.authToken = secureLocalStorage.getItem("authToken") as string
        this.currentTenant = secureLocalStorage.getItem("currentTenant") as ITenant
        this.availableTenants = secureLocalStorage.getItem("availableTenants") as ITenant[]
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`
    }

    async tenant(): Promise<ITenant> {
        return await this.request(Method.get, '/client/tenant')
    }

    async hosts(): Promise<WithId<IHost>[]> {
        return await this.request(Method.get, '/client/hosts')
    }

    async host(id: string): Promise<WithId<IHost>>{
        return await this.request(Method.get, `/client/host/${id}`)
    }

    async hostEvents(id: string): Promise<WithId<IEventGroup>[]>{
        return await this.request(Method.get, `/client/host/${id}/events`)
    }

    // all host events
    async discardAll(hostId: ObjectId) {
        return await this.request(Method.delete, `/client/host/${hostId.toString()}/events`)
    }

    async learnAll(hostId: ObjectId) {
        return await this.request(Method.post, `/client/host/${hostId.toString()}/learn`, {scope: 'all'})
    }

    // selection
    async learnSelected(hostId: ObjectId, ids: ObjectId[]) {
        const scope = { scope: 'groups', ids: ids}
        return await this.request(Method.post, `/client/host/${hostId.toString()}/learn`, scope)
    }

    async discardSelected(hostId: ObjectId, ids: ObjectId[]) {
        const scope = { scope: 'groups', ids: ids}
        return await this.request(Method.post, `/client/host/${hostId.toString()}/discard`, scope)
    }

    // service group
    async learnServiceGroup(groups: WithId<IEventGroup>[]) {
        const scope = { scope: 'groups', ids: groups.map((group) => group._id)}
        return await this.request(Method.post, `/client/host/${groups[0].host?._id.toString()}/learn`, scope)
    }

    async discardServiceGroup(groups: WithId<IEventGroup>[]) {
        const scope = { scope: 'groups', ids: groups.map((group) => group._id)}
        return await this.request(Method.post, `/client/host/${groups[0].host?._id.toString()}/discard`, scope)
    }

    // event group
    async learnEventGroup(group: WithId<IEventGroup>) {
        const scope = { scope: 'group', id: group._id}
        return await this.request(Method.post, `/client/host/${group.host?._id.toString()}/learn`, scope)
    }

    async discardEventGroup(group: WithId<IEventGroup>) {
        const scope = { scope: 'group', id: group._id}
        return await this.request(Method.post, `/client/host/${group.host?._id.toString()}/discard`, scope)
    }


    async updateFilter(hostId: ObjectId, filter: string|undefined) {
        return await this.request(Method.post, `/client/host/${hostId.toString()}/filter`, {filter: filter})
    }


    async addCommandToQueue(hostId: ObjectId | undefined, intent: TIntent) {
        if(!hostId) return
        const data = { host: hostId, intent: intent }
        return await this.request(Method.post, `/client/queue`, data)
    }

    async reset(hostId: ObjectId | undefined) {
        this.addCommandToQueue(hostId, 'reset')
    }

    async suspend(hostId: ObjectId | undefined) {
        this.addCommandToQueue(hostId, 'suspend')
    }

    async resume(hostId:ObjectId | undefined) {
        this.addCommandToQueue(hostId, 'resume')
    }

    async train(hostId: ObjectId | undefined) {
        this.addCommandToQueue(hostId, 'train')
    }

    async stats(filter = {}) {
        return await this.request(Method.post, '/client/dashboard', filter)
    }

    async latestEventGroups(hour: number) {
        return await this.request(Method.post, '/client/dashboard/latest', { hour: hour})
    }


    async explain(event: WithId<IEventGroup>): Promise<string> {
        return await this.request(Method.post, '/client/explain', { id: event._id})
    }

    async setup(data: {}) {
        return await this.request(Method.post, '/setup', data)
    }

    async signup(data: {}) {
        return await this.request(Method.post, '/signup', data)
    }

    async changePassword(currentPassword: string, newPassword: string, newPasswordConfirmation: string) {
        const data = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation
        }
        const result = await this.request(Method.post, '/client/password', data)
        return result
    }

    async authenticate(username: string, password: string): Promise<IAuthenticationResponse> {
        const data: IAuthenticationResponse = await this.request(Method.post, '/auth', {username: username, password: password})
        if(data.token && data.currentTenant) {
            this.authToken = data.token
            this.currentTenant = data.currentTenant
            this.availableTenants = data.availableTenants || []

            // store credentials
            secureLocalStorage.setItem("authToken", this.authToken!);
            secureLocalStorage.setItem("currentTenant", this.currentTenant);
            secureLocalStorage.setItem("availableTenants", this.availableTenants);
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`
        }
        return data
    }

    async logout() {
        const data = await this.request(Method.delete, '/client/auth')
        this.authToken = undefined
        this.currentTenant = undefined
        this.availableTenants = []
        secureLocalStorage.clear()
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`
        return data
    }


    private request = async (method: Method, path: string, data: object = {} ): Promise<any> => {
        console.log('request', method, path, data)
        // error handler callback
        const handleError = async (error: AxiosError) => {
            // check if could connect to server
            if(!error.response) {
                console.log("failed to connect to server")
                return // do not process next lines, because there's no error code to read
            }

            // connection was made, check http error code
            const code = error.response.status
            console.log('error code', code)

            // debug loop: wait 5 seconds
            // await new Promise(resolve => setTimeout(resolve, 5000))


            if(code === 401) {
                // session not found
                console.log('401: invalid session')
                this.authToken = undefined
                secureLocalStorage.clear() // .setItem("authToken", this.authToken!);
                axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`
                window.location.pathname = "/login"
                return
            }
            console.error('unhandled axios error:', error)
        }

        // setup request
        let request: Promise<AxiosResponse>

        switch (method) {
            case Method.post:
                request = axios.post(path, data)
                break;

            case Method.patch:
                request = axios.patch(path, data)
                break;

            case Method.delete:
                request = axios.delete(path, data)
                break;

            default: // get
                request = axios.get(path)
                break
        }


        // perform the request and pass errors to our handler
        await request.catch(async (error: AxiosError) => {
            handleError(error)
        })


        const responseData = (await request).data
        return responseData
    }
}