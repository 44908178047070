import { IEvent } from '../../../server/src/models/Event'
import { ObjectId, WithId } from "mongodb";

export default (props: {event: WithId<IEvent>}) => {
    return(
        <pre key={props.event._id.toString()}>
            { props.event.entry }
        </pre>
    )

}