import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    //bg: "#233044",
    bg: "#fff",
    color: "#333",
    hoverBg: "#d2dde7",
    activeBg: "#668ab1",
    activeColor: '#fff',
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  //mainBg: colors.grey["100"]
  mainBg: "#f2f2f5"
};

export default colorConfigs;