
import Dashboard from "../pages/Dashboard";
import Hosts from "../pages/Hosts";
import Host from "../pages/Host";
import Settings from '../pages/Settings';
import Profile from "../pages/Profile";

// https://mui.com/material-ui/material-icons/
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PersonIcon from '@mui/icons-material/Person';
import ComputerIcon from '@mui/icons-material/Computer';
import SettingsIcon from '@mui/icons-material/Settings';

import { RouteType } from "./config";

const appRoutes: RouteType[] = [
  {
    index: true,
    path: "/",
    element: <Dashboard />,
    state: "home",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <QueryStatsIcon />
    }
  },
  {
    path: "/hosts",
    element: <Hosts />,
    state: "hosts",
    sidebarProps: {
      displayText: "Hosts",
      icon: <ComputerIcon />
    },
  },
  {
    path: "/hosts/:id",
    element: <Host />,
    state: "hosts",
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsIcon />
    },
  },
  {
    path: "/profile",
    element: <Profile />,
    state: "settings",
  },

];

export default appRoutes;