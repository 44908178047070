import React, { PureComponent, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, TooltipProps, ResponsiveContainer, Bar, BarChart } from 'recharts';
import { ValueType, NameType, } from 'recharts/types/component/DefaultTooltipContent';

import Client from "../lib/Client"
import { IEventGroup } from '../../../server/src/models/EventGroup';
import { WithId } from 'mongodb';

import { Paper, Typography, Card, CardContent, Button } from '@mui/material/'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import EventGroup, { TableHeaderEvent } from '../components/EventGroup';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Explain from '../components/Explain';
import { Alert } from "../components/common/Alert";

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length && label) {
        let hour = ""
        try {
            hour = label.split(':')[0]
        } catch {}

      return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant='caption'>{`${hour}:00 - ${hour}:59`}</Typography>
                <Typography>{`${payload[0].value} Anomalies`}</Typography>
                <Typography variant='caption'>(click to filter)</Typography>
            </CardContent>
        </Card>
      )
    }
    return null
}

export default () => {
    const [data, setData] = useState()
    const [events, setEvents] = useState<WithId<IEventGroup>[]>([])
    const [filter, setFilter] = useState(-1)

    const [explainOpen, setExplainOpen] = useState(false)
    const [explainGroup, setExplainGroup] = useState<WithId<IEventGroup>>()

    const fetchData = () => {
        Client.instance.stats().then((stats) => {
            console.log("stats loaded")
            setData(stats)
            Client.instance.latestEventGroups(filter).then((latestEvents) => {
            console.log("events loaded")
            setEvents(latestEvents)
            })
        })
    }

    // fetch data whenever filter changes
    React.useEffect(() => { fetchData() }, [filter])

    const reload = () => {
      fetchData()
    }

    const handleExplain = (group: WithId<IEventGroup>) => {
        setExplainGroup(group)
        setExplainOpen(true)
    }
    //<Typography variant="h3" gutterBottom><QueryStatsIcon fontSize='large'/> Dashboard</Typography>

    return(
        <React.Fragment>
            <Explain open={explainOpen} setOpen={setExplainOpen} group={explainGroup}/>

            { false && !data &&
                    <Alert severity="success">
                        No anomalies reported during the last 24 hours.
                    </Alert>
            }
            { data &&
                <Paper>
                    <Typography variant="h5" gutterBottom style={{padding:30}}>History</Typography>


                    <ResponsiveContainer width="100%" height={300} >
                        <BarChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 80,
                                bottom: 20,
                                left: 20,
                            }}
                            onClick={(e) => {
                                if(e && e.activeLabel != null) {
                                    const hour = parseInt(e.activeLabel.split(':')[0])
                                    setFilter(hour)
                                }
                                else setFilter(-1)
                            }}
                        >
                            <XAxis dataKey="hour" />
                            <YAxis scale="sqrt"/>
                            <Tooltip content={<CustomTooltip />} />
                            <Bar dataKey="value" fill="orange"  />
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
            }


            <Paper style={{padding: 30, marginTop:30}}>
                { filter == -1 &&
                    <Typography variant="h5" gutterBottom>Latest Anomalies</Typography>
                }
                { filter > -1 &&
                    <Typography variant="h5" gutterBottom>{filter}:00 - {filter}:59
                        <Button variant="text" onClick={() => {
                            setFilter(-1)
                        }} style={{marginBottom: 10, marginLeft: 10}}>
                        Reset
                        </Button>
                  </Typography>
                }
                <TableContainer>
                    <Table>
                        <TableHeaderEvent showHost/>
                        <TableBody>
                            {
                            events.map((e) => <EventGroup
                                                    group={e}
                                                    showHost
                                                    showServiceName
                                                    key={e._id.toString()}
                                                    reloadFunction={reload}
                                                    handleExplain={handleExplain}
                                                    />)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                { events.length == 0 &&
                <Typography>
                    No anomalies reported yet.
                </Typography>
                }
            </Paper>
        </React.Fragment>
    )
};
