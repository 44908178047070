import React, { useState } from "react";

import Client from "../lib/Client"

import { IHost } from '../../../server/src/models/Host'

import { WithId } from 'mongodb'

import { TextField, Paper, Tooltip, IconButton, Button,  MenuItem, FormControl, Select, SelectChangeEvent, FormGroup, Snackbar } from '@mui/material/'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import HelpIcon from '@mui/icons-material/Help';
import { Alert } from "../components/common/Alert";


export const HostStatus = (props: { details: WithId<IHost> }) => {
    props.details.filter ||= ''
    const [filter, setFilter] = useState<string>(props.details.filter)
    const [selectedMode, setSelectedMode] = useState(props.details.mode)
    const [showSaveNotification, setShowSaveNotification] = useState(false)

    const handleModeChange = (event: SelectChangeEvent) => {
        const newMode = event.target.value
        if(newMode === props.details.mode) return

        switch (newMode) {
            case 'monitor':
                Client.instance.resume(props.details._id)
                break;

            case 'suspended':
                Client.instance.suspend(props.details._id)
                break;

            case 'train':
                Client.instance.train(props.details._id)
                break;

            default:
                break;
        }
        setSelectedMode(newMode)
    }

    return(
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Mode</TableCell>
                        <TableCell>Filter</TableCell>
                        <TableCell>Agent</TableCell>
                        <TableCell>Epoch</TableCell>
                        <TableCell>Loss</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            { selectedMode === 'init' &&
                            <>Initializing</>
                            }
                            { selectedMode !== 'init' &&

                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginTop:3 }}>
                                <Select
                                    name="form-select-change-mode"
                                    value={selectedMode}
                                    label="Mode"
                                    onChange={handleModeChange}
                                >
                                <MenuItem value="monitor"><CircleIcon sx={{fontSize: 12, color: "green", marginRight: 1}}/> Monitor</MenuItem>
                                <MenuItem value="suspended"><CircleIcon sx={{fontSize: 12, color: "orange", marginRight: 1}}/>Suspend</MenuItem>
                                <MenuItem value="train"><CircleIcon sx={{fontSize: 12, color: "purple", marginRight: 1}}/>Learn</MenuItem>
                                </Select>
                            </FormControl>
                            }


                            </TableCell>
                        <TableCell>
                            <FormGroup row>
                                <TextField
                                    id="filter-input"
                                    variant="standard"
                                    label="Regex Filter"
                                    value={filter}
                                    onChange={(e) => { setFilter(e.target.value)} }
                                    />
                                { filter !== props.details.filter &&
                                <Button variant="text" style={{marginLeft: 10}} onClick={() => {
                                    Client.instance.updateFilter(props.details._id, filter).then(() => {
                                        props.details.filter = filter
                                        setShowSaveNotification(true)
                                    })

                                }}>Save</Button> }
                                <Tooltip title="The regular expression set here will be applied on the monitored event log. Events not matching this expression are ignored. Leave blank to monitor all events.">
                                    <IconButton>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </FormGroup>
                            <Snackbar open={showSaveNotification} autoHideDuration={6000} onClose={() => { setShowSaveNotification(false) }}>
                            <Alert severity="success" sx={{ width: '100%' }}>
                                Filter successfully updated.
                            </Alert>
                            </Snackbar>
                        </TableCell>
                        <TableCell>
                            v{props.details.agent_version}
                        </TableCell>
                        <TableCell>{props.details.epochs}</TableCell>
                        <TableCell>{props.details.loss.toFixed(2)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default HostStatus