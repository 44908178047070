import React from "react";
import Client from "../lib/Client"
import { IHost } from '../../../server/src/models/Host'

import { WithId } from "mongodb";
import { Paper, Typography, Link } from '@mui/material/'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import Status from "../components/Status";
import ComputerIcon from '@mui/icons-material/Computer';
import OS from "../components/common/OS";

const Ratio = (props: {host: IHost}) => {
    let ratio = 0
    if(props.host.eventsCount && props.host.eventsCount > 0)
        ratio = props.host.numLinesProcessed / props.host.eventsCount

    let color = ''
    if(ratio < 30) color = 'red'
    if(ratio == 0) return <>n/a</>

    return(
        <div style={{color: color}}>
            1:{ratio.toFixed(0)}
        </div>
    )
}

export default () => {
    const [hosts, setHosts] = React.useState<WithId<IHost>[]>([])
    const [loaded, setLoaded] = React.useState(false);

    const fetchData = React.useCallback(() => {
        Client.instance.hosts().then((data) => {
            setHosts(data)
            console.log("load")
            //console.log(data)
            setLoaded(true)
            })
        }, [hosts, loaded])

    if(!loaded) fetchData()


    // <Typography variant="h3" gutterBottom><ComputerIcon fontSize="large"/> Hosts</Typography>

    return(
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={20} />
                            <TableCell>Hostname</TableCell>
                            <TableCell>Mode</TableCell>
                            <TableCell>Filter</TableCell>
                            <TableCell># Processed</TableCell>
                            <TableCell># Reported</TableCell>
                            <TableCell>Ratio</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { hosts.map((host) => {
                            return(
                                <TableRow key={host._id.toString()} >
                                    <TableCell>
                                        <Status host={host} />
                                    </TableCell>
                                    <TableCell>
                                        <Link href={`/hosts/${host._id}`}>{host.name}</Link>
                                    </TableCell>
                                    <TableCell>{host.mode}</TableCell>
                                    <TableCell>{host.filter}</TableCell>
                                    <TableCell>{host.numLinesProcessed}</TableCell>
                                    <TableCell>{host.eventsCount}</TableCell>
                                    <TableCell><Ratio host={host}/></TableCell>
                                </TableRow>
                            )
                        }) }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
